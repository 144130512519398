import {IClientUser} from "../components/Authenticate";
import jwt_decode from "jwt-decode";
import {IApiSuccessResponse} from "./ApiClient";
import axios from "axios";
import config from "../../config/config";

interface IConstructor {
    storageKey?: string;
}

export class ManageBearerToken {

    storageKey: string;
    token: string;

    constructor({storageKey = 'zen_token'}: IConstructor = {}) {

        this.storageKey = storageKey;
        this.token = '';
    }

    setToken(token: string) {

        localStorage.setItem(this.storageKey, token)

    }

    async getToken(forceRefresh: boolean = false) {

        try {

            const token = localStorage.getItem(this.storageKey);

            if (!token) return null;

            const {exp}: { data: any, exp: number, iat: number } = jwt_decode(token);

            const currentTime: number = Math.floor(new Date().getTime() / 1000);

            
            if (!forceRefresh && currentTime < exp) {
                return token;
            }

            // Token expired, refresh it on the server.
            const headerObject = {
                headers: {Authorization: `Bearer ${token}`}
            }

            const response = await axios.post<IApiSuccessResponse<{
                token: string
            }>>(`${config.apiServer}/auth/v1/tokenops/refresh`, {}, headerObject);

            const newToken = response.data.token

            if (!newToken) throw new Error("Could not refresh token");

            this.setToken(newToken);

            return newToken;

        } catch (err: any) {

            console.error(`Error getting token: ${err.message}`);
            throw new Error(`Error getting token: ${err.message}`);

        }

    }

    getUser(): IClientUser | null {

        const token = localStorage.getItem(this.storageKey);

        if (!token) return null

        const decoded: any = jwt_decode(token);
        const userData: IClientUser = decoded?.data?.user;

        return userData;

    }

    tokenValid() {

        const token = localStorage.getItem(this.storageKey);
        if (!token) return false;

        try {

            const decoded: any = jwt_decode(token);
            const expiry: number | null = decoded?.exp;

            if (!expiry) return false;

            const tokenValid = (expiry * 1000) > Date.now();

            if (!tokenValid) {
                this.deleteToken();
                return false;
            }

            return true;

        } catch (err: any) {

            console.error(`[sodfmio] Error processing token: ${err}`);
            throw new Error(err);

        }

    }

    deleteToken() {
        localStorage.removeItem(this.storageKey)
    }

}
