import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {
    EActiveStatus,
    ETaskPriority,
    ETaskStatus,
    IZenChecklistItem,
    IZenContext,
    IZenTask
} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../../../../components/GlobalAlert";
import ApiClient from "../../../../../lib/ApiClient";
import {KanbanBoardContext} from "../../index";
import {GlobalContext} from "../../../../../lib/GlobalContextProvider";
import ZenButton from "../../../../../components/ZenButton";
import ZenMiniAlert, {EMiniAlertType} from "../../../../../components/ZenMiniAlert";
import _ from "lodash";
import ZenDropdown from "../../../../../components/ZenDropdown";
import Tooltip from "../../../../../components/Tooltip";
import {Editor} from "@tinymce/tinymce-react";
import ZenChecklist from "./ZenChecklist";
import TaskSuggestionsSection from "./TaskSuggestionsSection";
import TasklistSection from "./TasklistSection";
import DOMPurify from "dompurify";

type EGoalAction = 'home' | 'suggestions' | 'tasklist'

interface Props {
    status: ETaskStatus;
    editTask: IZenTask | null; // Task is being edited
    setEditTask: (value: IZenTask | null) => void
}

const AddTaskModal = ({status, editTask, setEditTask}: Props) => {

    const apiClient = new ApiClient();
    const {showAlert} = useContext(GlobalAlertContext)
    const {getTaskList} = useContext(KanbanBoardContext);
    const {availableContexts, currentContext} = useContext(GlobalContext)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [formDirty, setFormDirty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [goalAction, setGoalAction] = useState<EGoalAction>('home');
    const [saved, setSaved] = useState<boolean>(false);

    const initialState: IZenTask = {
        context: currentContext?._id ? currentContext._id : null,
        index: 0,
        priority: ETaskPriority.normal,
        userId: '',
        title: '',
        description: '',
        status,
        childTasks: [],
        suggestions: [],
        checklist: [],
        taskList: [],
        activeStatus: EActiveStatus.active,
        resources: [],
        notes: []
    }

    const [task, setTask] = useState<IZenTask>(editTask || initialState)

    useEffect(
        () => {

            // The only purpose of this hook is to initialise the component if the task is being edited.
            if (!editTask || !!task?._id) return;

            setTask(editTask);
            setShowModal(true);


        }, [editTask]
    )

    useEffect(
        () => {

            if (_.isEqual(editTask, task) || _.isEqual(initialState, task) || !task.title) return;
            if (!task._id) return; // Don't create tasks automatically

            updateDebounced(task);

        }, [task]
    )
    
    const selectContext = (index: number | null | undefined) => {

        if (typeof index !== "number") {
            return setTask({...task, context: null})
        }

        const context = availableContexts[index]

        if (!context?._id) return;

        setTask({...task, context: context._id})

    }

    useEffect(
        () => {

            setTask({...task, context: currentContext?._id ? currentContext._id : null})

        }, [currentContext] // Global context has changed? Does this ever h
    )

    const handleInput = (field: string, value: string) => {

        setTask(
            {
                ...task,
                [field]: value
            }
        )

    }

    const createOrUpdateTask = (taskUpdate: IZenTask) => {

        setLoading(true);

        const client = !!taskUpdate?._id ? apiClient.put<IZenTask>(`api/v1/tasks/${taskUpdate._id}`, {payload: {task: taskUpdate}}) :
            apiClient.post<IZenTask>('api/v1/tasks', {payload: {task: taskUpdate}});

        client.then(
            (createdOrUpdatedTask) => {

                setLoading(false);
                setSaved(true);
                setFormDirty(false);

                setEditTask({...taskUpdate, _id: createdOrUpdatedTask._id});
                getTaskList(currentContext?._id ? currentContext._id.toString() : null);

            }
        ).catch(
            (err: any) => {

                setLoading(false);
                console.error(err);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const updateDebounced = useCallback(_.debounce(createOrUpdateTask, 1000), []);

    const deleteTask = () => {

        if (!confirm('Are you sure you would like to delete this goal?')) return;

        apiClient.delete(`api/v1/tasks/${task._id}`).then(
            () => {

                setLoading(false);
                getTaskList(currentContext?._id ? currentContext._id.toString() : null);
                setTask(initialState);
                setShowModal(false);

            }
        ).catch(
            (err: any) => {

                setLoading(false);
                console.error(err);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const closeModal = useMemo(() => (ev: any) => {

        if(!saved && formDirty && !confirm('Your task has unsaved changes. Click the save button to save your task. Continue and lose changes?')){
            return;
        }

        ev.stopPropagation();
        setTask(initialState);
        setEditTask(null);
        setFormDirty(false);
        setSaved(false);
        setShowModal(false);

    }, [saved, formDirty]);

    return (
        <>
            <button type="button"
                    className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    onClick={() => {
                        setShowModal(true);
                        setFormDirty(false);
                    }}
            >
                Add Task
            </button>

            {showModal &&
                <>

                    <div onClick={(ev) => {
                        closeModal(ev)
                    }}
                         className="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

                    <div
                        className="hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">

                        <div
                            className={`mt-7 ${showModal ? 'opacity-100' : 'opacity-0'} duration-300 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto`}
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    closeModal(e);
                                }
                            }}
                        >

                            <div
                                className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                                <div
                                    className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                                    <h3 className="font-bold text-gray-800 dark:text-white">
                                        {editTask ? `${editTask.title}` : 'Add new task'}
                                    </h3>
                                    <button type="button"
                                            onClick={closeModal}
                                            className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                                            data-hs-overlay={`add-task-${status}`}>
                                        <span className="sr-only">Close</span>
                                        <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                                fill="currentColor"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="modal-content p-4 overflow-y-auto">

                                    <div className={`goal-options mb-4  ${goalAction === 'home' ? '' : 'hidden'}`}>

                                        <p className={'text-sm mb-4 w-3/4 ml-[12.5%] text-center'}>
                                            Manage the properties of your task; use the magic of Artificial
                                            Intelligence to power-up your task
                                        </p>

                                        <div className={'buttons-container flex flex-col'}>

                                            <button
                                                type={'button'}
                                                disabled={ !task._id }
                                                className='disabled:cursor-not-allowed mb-2 py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800'
                                                onClick={(ev) => {

                                                    ev.preventDefault();
                                                    ev.stopPropagation();

                                                    setGoalAction('suggestions');

                                                }}>

                                                { !task.suggestions.length &&
                                                    <span>Get AI powered suggestions to achieve this goal</span>}

                                                {
                                                    !!task.suggestions.length &&
                                                    <span>Manage { task.suggestions.length } suggestions for achieving this goal</span>
                                                }

                                                <span
                                                    className="inline-flex justify-center items-center rounded-full text-yellow-500">
                                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                                             width="16" height="16"
                                                             fill="currentColor" viewBox="0 0 16 16">
                                                            <path
                                                                d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                                                        </svg>
                                                    </span>
                                            </button>

                                            <button
                                                type={'button'}
                                                disabled={ !task._id }
                                                className='disabled:cursor-not-allowed py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800'
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    ev.stopPropagation();
                                                    setGoalAction('tasklist')
                                                }}>
                                                Create an AI powered action plan to achieve this goal
                                                <span
                                                    className="inline-flex justify-center items-center rounded-full text-yellow-500">
                                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                                             width="16" height="16"
                                                             fill="currentColor" viewBox="0 0 16 16">
                                                            <path
                                                                d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                                                        </svg>
                                                    </span>
                                            </button>

                                            {
                                                <span
                                                    className={`${ task._id && 'opacity-0' } text-xs bg-red-500 text-white inline-block py-1 px-3 mt-2 rounded-s`}>
                                                    create a task title to enable AI functionality
                                                </span>
                                            }

                                        </div>


                                        <div className={'pt-2'}>

                                            {!!availableContexts.length &&

                                                <div className={'flex items-center mb-4'}>
                                                    <label htmlFor="context"
                                                           className="text-sm dark:text-white text-left mr-2">Context</label>

                                                    <ZenDropdown<IZenContext>
                                                        selectNoneOption={true}
                                                        selectedItem={!!task.context ? task.context.toString() : null}
                                                        items={availableContexts}
                                                        onClick={(index) => {
                                                            selectContext(index)
                                                        }
                                                        }/>

                                                    <Tooltip
                                                        toolTip={`Assign this goal to a context or category: 'Home' or 'Work' for example`}
                                                        className={'ml-2'}
                                                        iconClasses={'bg-black text-white'}
                                                    />

                                                </div>

                                            }

                                            <label htmlFor="title"
                                                   className="block text-sm mb-2 dark:text-white text-left">Title<sup>*</sup></label>
                                            <div className="relative mb-2">
                                                <input type="text"
                                                       id="title"
                                                       value={task.title}
                                                       onChange={(event) => {
                                                           handleInput('title', event.target.value)
                                                       }}
                                                       onBlur={() => {
                                                           setFormDirty(true);
                                                       }}
                                                       className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                       aria-describedby="title"/>

                                                {formDirty && !task.title.length &&
                                                    <p
                                                        className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                                        id="email-error">
                                                        this field is required
                                                    </p>
                                                }

                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="description"
                                                   className="block text-sm mb-2 dark:text-white text-left">Description</label>

                                            <div className="relative">

                                                <Editor
                                                    apiKey='egb518kqdaeb0sk0eujh61phf6jl6pdfg47cmnmmagn6s4t9'
                                                    value={task.description}
                                                    onEditorChange={(editorState) => {
                                                        setTask({...task, description: DOMPurify.sanitize(editorState)})
                                                    }}
                                                    init={{
                                                        height: 200,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />

                                                <div
                                                    className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                    <svg className="h-5 w-5 text-red-500" width="16"
                                                         height="16"
                                                         fill="currentColor"
                                                         viewBox="0 0 16 16" aria-hidden="true">
                                                        <path
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                    </svg>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={'checklist mt-3 pt-2'}>

                                            <ZenChecklist
                                                checklistItems={task.checklist}
                                                updateParent={
                                                    (checklist: IZenChecklistItem[])=>{
                                                        setTask({...task, checklist})
                                                    }
                                                } />

                                        </div>


                                    </div>

                                    <div className={`${goalAction === 'suggestions' ? '' : 'hidden'}`}>

                                        <TaskSuggestionsSection
                                            task={task}
                                            updateTask={ (updatedTask: IZenTask)=> setTask(updatedTask) }/>

                                    </div>

                                    <div className={`${goalAction === 'tasklist' ? '' : 'hidden'}`}>

                                        <TasklistSection task={task} updateTask={ (updatedTask)=>{ console.log(updatedTask) } } />

                                    </div>

                                </div>

                                <div
                                    className="flex items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                                    {!!editTask &&
                                        <ZenButton
                                            type="button"
                                            label='delete'
                                            className="btn btn-danger mr-auto"
                                            onClick={() => {
                                                deleteTask();
                                            }}/>
                                    }

                                    <div className='ml-auto'>

                                        {loading &&
                                            <ZenMiniAlert message={'please wait...'} type={EMiniAlertType.info}/>}

                                        {saved && <ZenMiniAlert message={'task saved'} type={EMiniAlertType.success}/>}

                                        {!task._id &&
                                            <ZenButton
                                                label='save'
                                                zenType='primary'
                                                type='button'
                                                disabled={loading}
                                                className='mr-2'
                                                onClick={() => {
                                                    createOrUpdateTask(task)
                                                }}/>
                                        }


                                        <ZenButton label='close' zenType='default' onClick={(ev) => {
                                            closeModal(ev);
                                        }}/>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </>

            }


        </>
    )

}

export default AddTaskModal
