import React, { useContext, useMemo } from 'react';
import { AuthContext } from "../../../components/Authenticate";
import { Link } from 'react-router-dom';

const AccountBanner = () => {

    const { user } = useContext(AuthContext);

    // Calculate daysRemaining using useMemo
    const daysRemaining = useMemo(() => {

        if (user?.freeTrial?.expires) {
            const expires = new Date(user.freeTrial.expires);
            const now = new Date();
            const diffTime = Math.abs(expires.getTime() - now.getTime());
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }

        return 0;
        
    }, [user?.freeTrial?.expires]);

    const stripeSubscription = user?.stripeSubscription?.[0];

    // User subscribed, show nothing.
    if(!user || stripeSubscription) {
        return null;
    }

    if(daysRemaining > 0 ) {
        return (
            <div className={`flex items-center ${ daysRemaining > 3 ? 'bg-blue-500' : 'bg-red-500' } p-2 justify-center`}>
                <div className='text-white'>
                    You have {daysRemaining} days remaining in your free trial,
                    why not <Link to="/subscription" className='underline'>discover the great benefits of a subscription?</Link>
                    </div>
            </div>
        )   
    }

    if(daysRemaining <= 0 ) {
        return (
            <div className='flex items-center bg-red-500 p-1 justify-center'>
                <div className='text-white'>
                    Your free trial has expired, why not <Link to="/subscription" className='underline'>upgrade to a subscription?</Link>.
                </div>
            </div>
        )
    }

    // TODO: Warning banner for expiring subscription.
    // TODO: Warning banner for low balance.
    return null;

}

export default AccountBanner;