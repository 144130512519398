{
  "name": "zen-client",
  "version": "1.5.6",
  "description": "Client for the goalzen application",
  "engines": {
    "node": "18.18.2"
  },
  "targets": {
    "browserModern": {
      "engines": {
        "browsers": [
          "last 1 Chrome version"
        ]
      }
    }
  },
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1",
    "start": "parcel serve ./index.html --open --port 8000",
    "build": "parcel build ./index.html"
  },
  "author": "James Drummond",
  "license": "ISC",
  "dependencies": {
    "@fortawesome/fontawesome-svg-core": "^6.4.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.4.2",
    "@fortawesome/react-fontawesome": "^0.2.0",
    "@parcel/transformer-image": "^2.12.0",
    "@parcel/transformer-less": "^2.12.0",
    "@preline/overlay": "^1.4.0",
    "@react-oauth/google": "^0.11.0",
    "@stripe/react-stripe-js": "^2.3.1",
    "@stripe/stripe-js": "^2.1.10",
    "@szhsin/react-accordion": "^1.2.3",
    "@tailwindcss/forms": "^0.5.7",
    "@tailwindcss/typography": "^0.5.9",
    "@tinymce/tinymce-react": "^5.1.1",
    "@types/dompurify": "^3.0.5",
    "@types/jest": "^29.5.1",
    "@types/lodash": "^4.14.202",
    "@types/node": "^18.19.55",
    "@types/react": "^18.2.6",
    "@types/react-dom": "^18.2.4",
    "@types/ws": "^8.5.12",
    "@typescript-eslint/eslint-plugin": "^5.59.11",
    "@typescript-eslint/parser": "^5.59.11",
    "@yellowmelon/zen-global-types": "^1.0.94",
    "assert": "^2.1.0",
    "axios": "^1.4.0",
    "babel-plugin-macros": "^3.1.0",
    "buffer": "^5.7.1",
    "crypto-browserify": "^3.12.0",
    "date-fns": "^4.1.0",
    "dnd-core": "^16.0.1",
    "dompurify": "^3.0.6",
    "dotenv": "^16.3.1",
    "eslint": "^8.42.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-config-airbnb-typescript": "^17.0.0",
    "eslint-plugin-import": "^2.25.3",
    "eslint-plugin-jsx-a11y": "^6.5.1",
    "eslint-plugin-react": "^7.28.0",
    "eslint-plugin-react-hooks": "^4.3.0",
    "events": "^3.3.0",
    "i18next": "^23.4.6",
    "i18next-browser-languagedetector": "^7.1.0",
    "i18next-http-backend": "^2.2.2",
    "jest": "^29.5.0",
    "jwt-decode": "^3.1.2",
    "less": "^4.1.3",
    "lodash": "^4.17.21",
    "marked": "^12.0.2",
    "os-browserify": "^0.3.0",
    "parcel": "^2.12.0",
    "path-browserify": "^1.0.1",
    "postcss": "^8.4.24",
    "preline": "^1.9.0",
    "process": "^0.11.10",
    "query-string": "^9.0.0",
    "querystring-es3": "^0.2.1",
    "react": "^18.2.0",
    "react-dnd": "^16.0.1",
    "react-dnd-html5-backend": "^16.0.1",
    "react-dnd-touch-backend": "^16.0.1",
    "react-dom": "^18.2.0",
    "react-i18next": "^13.2.2",
    "react-router-dom": "^6.11.2",
    "react-select": "^5.8.0",
    "react-swipeable": "^7.0.1",
    "stream-browserify": "^3.0.0",
    "tailwindcss": "^3.3.2",
    "theme-change": "^2.5.0",
    "typescript": "^5.6.3",
    "ws": "^8.18.0"
  }
}
